// src/components/Portfolio.js
import React from 'react';
import { Link } from 'react-router-dom';
import './portfolio.css'; // CSS dosyanızın yolu

const projects = [
    { id: 1, title: 'Project 1', description: 'Description of project 1.', image: 'https://via.placeholder.com/300' },
    { id: 2, title: 'Project 2', description: 'Description of project 2.', image: 'https://via.placeholder.com/300' },
    { id: 3, title: 'Project 3', description: 'Description of project 3.', image: 'https://via.placeholder.com/300' },
    { id: 4, title: 'Project 4', description: 'Description of project 4.', image: 'https://via.placeholder.com/300' },
    { id: 5, title: 'Project 5', description: 'Description of project 5.', image: 'https://via.placeholder.com/300' },
];

const Portfolio = () => {
    return (
        <div className="portfolio-container">
            <h1>Portfolio</h1>
            <div className="portfolio-grid">
                {projects.map(project => (
                    <div key={project.id} className="portfolio-card">
                        <img src={project.image} alt={project.title} className="portfolio-image" />
                        <h2>{project.title}</h2>
                        <p>{project.description}</p>
                        <Link to={`/portfolio/${project.id}`} className="view-details link">Continue Reading</Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Portfolio;
