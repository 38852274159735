// Contact.js
import React from 'react';
import './contact.css'; // Import the CSS file

const contactDetails = [
    { label: 'Phone', value: '+123 456 7890' },
    { label: 'Email', value: 'info@example.com' },
    { label: 'Address', value: '1234 Example Street, City, Country' },
];

const Contact = () => {
    return (
        <section className="contact-section">
            <div className="contact-container">
                <div className="contact-form">
                    <h1>Contact Us</h1>
                    <form>
                        <label htmlFor="name">Your Name</label>
                        <input type="text" id="name" name="name" required />

                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" name="email" required />

                        <label htmlFor="message">Message</label>
                        <textarea id="message" name="message" rows="4" required></textarea>

                        <button type="submit">Send</button>
                    </form>
                </div>
                <div className="contact-info">
                    <h2>Contact Information</h2>
                    <ul>
                        {contactDetails.map((detail, index) => (
                            <li key={index}>
                                <strong>{detail.label}:</strong> {detail.value}
                            </li>
                        ))}
                    </ul>
                    <div className="map-container">
                        <h2>Our Location</h2>
                        <iframe
                            title="Google Map"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.2146628592905!2d-122.41626428468165!3d37.7790264797585!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085808bcd1b1d71%3A0x42d5c35d51f9639!2s1%20Market%20St%2C%20San%20Francisco%2C%20CA%2094135%2C%20USA!5e0!3m2!1sen!2str!4v1622099130421!5m2!1sen!2str"
                            width="100%"
                            height="300"
                            frameBorder="0"
                            style={{ border: 0 }}
                            allowFullScreen
                            aria-hidden="false"
                            tabIndex="0"
                        ></iframe>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;
