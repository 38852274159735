import React from "react";
import './content-right-2.css';
import {useNavigate} from "react-router-dom";

const ContentRight2 = () => {
    const navigate= useNavigate();

    const data = [
        { id: 1, image: 'https://via.placeholder.com/50', title: 'Service 1' },
        { id: 2, image: 'https://via.placeholder.com/50', title: 'Service 2' },
        { id: 3, image: 'https://via.placeholder.com/50', title: 'Service 3' },
        { id: 4, image: 'https://via.placeholder.com/50', title: 'Service 4' },
        { id: 5, image: 'https://via.placeholder.com/50', title: 'Service 5' },
    ];

    return (
        <div className="data-list" onClick={() => navigate('services-detail')}>
            {data.map(item => (
                <div key={item.id} className="data-item">
                    <img src={item.image} alt={`Image for ${item.title}`} />
                    <h3>{item.title}</h3>
                </div>
            ))}
        </div>
    );
};

export default ContentRight2;
