import React from 'react';
import './footer-section.css'; // Stil dosyasını import edin

const FooterSection = () => {
    return (
        <div className="footer-section">
            <div className="text-content">
                <h2>Lorem Ipsum</h2>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            </div>
            <div className="image-content">
                <img src="https://via.placeholder.com/600x300" alt="Resim" />
            </div>
        </div>
    );
};

export default FooterSection;
