// About.js
import React from 'react';
import './about.css'; // CSS dosyasını içe aktar

const About = () => {
    return (
        <section className="about-section">
            <div className="about-container">
                <img src="https://via.placeholder.com/300" alt="Profil Resmi" className="profile-image" />
                <div className="about-content">
                    <h1>About</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum. Integer imperdiet, felis nec consectetur aliquet, ex ex tincidunt ligula, non facilisis mi nisl in ante.</p>
                    <p>Aliquam erat volutpat. Morbi euismod arcu non enim volutpat, non elementum libero tincidunt. Praesent sit amet tincidunt libero. Donec eget augue eu neque pharetra vestibulum.</p>
                </div>
            </div>
        </section>
    );
};

export default About;
