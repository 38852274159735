import React from 'react';
import './footer.css';
import {FaFacebook, FaInstagram, FaLinkedin} from "react-icons/fa";
import {Link} from "react-router-dom"; // Stil dosyasını import edin

const Footer = () => {

    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-section-f">
                    <h4><Link to={`/about`} className="link">About</Link></h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </div>
                <div className="footer-section-f">
                    <h4><Link to={`/contact`} className="link">Contact</Link></h4>
                    <ul>
                        <li><a href="#">E-mail</a></li>
                        <li><a href="#">GSM</a></li>
                        <li><a href="#">Address</a></li>
                    </ul>
                </div>
                <div className="footer-section-f">
                    <h4><Link to={`/services`} className="link">Services</Link></h4>
                    <ul>

                        <li><Link to={`/services-detail`} className="link">Service 1</Link></li>
                        <li><Link to={`/services-detail`} className="link">Service 2</Link></li>
                        <li><Link to={`/services-detail`} className="link">Service 3</Link></li>
                        <li><Link to={`/services-detail`} className="link">Service 4</Link></li>
                        <li><Link to={`/services-detail`} className="link">Service 5</Link></li>
                    </ul>
                </div>
                <div className="footer-section-f">
                    <h4>Social Media</h4>
                    <ul className="social-media">

                        <li><a href="#"><FaLinkedin/></a></li>
                        <li><a href="#"><FaInstagram /></a></li>
                        <li><a href="#"><FaFacebook /></a></li>
                    </ul>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
