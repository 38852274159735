import NavbarMenuLight from "./navbar-menu/navbar-menu-light";
import Footer from "./footer/footer";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import HomePage1 from "./home-page/option-1/home-page-1";
import Detail from "./detail/detail";
import About from "./about/about";
import Contact from "./contact/contact";
import './App.css';
import Services from "./services/services";
import ServicesDetail from "./detail/services-detail";
import PortfolioDetail from "./portfolio/portfolio-detail";
import HomePage2 from "./home-page/option-2/home-page-2";

function App() {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Router>
                <NavbarMenuLight/>
                <div className="main-content">
                    <Routes>
                        <Route path="/" element={<HomePage1/>}/>
                        <Route path="/home-page-1" element={<HomePage1/>}/>
                        <Route path="/home-page-2" element={<HomePage2/>}/>
                        <Route path="/detail" element={<Detail/>}/>
                        <Route path="/detail" element={<Detail/>}/>
                        <Route path="/about" element={<About/>}/>
                        <Route path="/contact" element={<Contact/>}/>
                        <Route path="/services" element={<Services/>}/>
                        <Route path="/services-detail" element={<ServicesDetail/>}/>
                        <Route path="/portfolio/:id" element={<PortfolioDetail/>}/>
                    </Routes>
                </div>
                <Footer/>
            </Router>

        </div>
    );
}

export default App;
