import React from 'react';
import './content-1.css';
import ContentLeft from "./content-left";
import ContentRight2 from "../option-2/content-right-2"; // İçeriğin stil dosyasını import edin

const Content1 = () => {
    return (
        <div className="content-container">
            <ContentLeft/>
            <ContentRight2/>
        </div>
    );
};

export default Content1;
