import React, { useState, useEffect } from 'react';
import './slider-1.css'; // CSS dosyasını import edin

const Slider1 = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const slideCount = 3; // Slider1 içeriği sayısı

    const goToPrevious = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? slideCount - 1 : prevIndex - 1
        );
    };

    const goToNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === slideCount - 1 ? 0 : prevIndex + 1
        );
    };

    useEffect(() => {
        const interval = setInterval(goToNext, 3000); // 3 saniyede bir otomatik geçiş
        return () => clearInterval(interval); // Temizlik
    }, []);

    return (
        <div className="slider-container">
            <div
                className="slider-wrapper"
                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
                <div className="slider-item">
                    <img src="https://via.placeholder.com/1000x400?text=Slide+1" alt="Slide 1" />
                </div>
                <div className="slider-item">
                    <img src="https://via.placeholder.com/1000x400?text=Slide+2" alt="Slide 2" />
                </div>
                <div className="slider-item">
                    <img src="https://via.placeholder.com/1000x400?text=Slide+3" alt="Slide 3" />
                </div>
            </div>
            <button className="slider-button prev" onClick={goToPrevious}>
                &#10094;
            </button>
            <button className="slider-button next" onClick={goToNext}>
                &#10095;
            </button>
        </div>
    );
};

export default Slider1;
