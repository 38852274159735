import React, {useState} from 'react';
import './navbar-menu-light.css';
import {Link, useNavigate} from "react-router-dom";

const NavbarMenuLight = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null);
    const navigate = useNavigate();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const toggleDropdown = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };


    const handleLogoClick = () => {
        navigate('/'); // Ana sayfaya yönlendirir
        setIsOpen(false); // Menü kapatılır
    };

    const handleLinkClick = () => {
        setIsOpen(false); // Menü kapatılır
    };

    return (
        <nav className="navbar">
            <div onClick={handleLogoClick} style={{ cursor: 'pointer' }} className="logo" >Logo</div>
            <ul className={`nav-links ${isOpen ? 'active' : ''}`}>
                <li className="nav-item nav-item-no-dropdown" onMouseEnter={() => toggleDropdown(1)}>
                    <Link to={`/`} className="link">Home</Link>
                    <ul className={`dropdown ${activeIndex === 1 ? 'active' : ''}`}>
                        <li><Link to={`/home-page-1`} className="link" onClick={handleLinkClick}>HomePage 1</Link></li>
                        <li><Link to={`/home-page-2`} className="link" onClick={handleLinkClick}>HomePage 2</Link></li>
                    </ul>
                </li>
                <li className="nav-item" onMouseLeave={() => toggleDropdown(null)}>
                    <Link to={`/about`} className="link" onClick={handleLinkClick}>About</Link>
                </li>
                <li className="nav-item" onMouseEnter={() => toggleDropdown(2)} onMouseLeave={() => toggleDropdown(null)}>
                    <Link to={`/services`} className="link">Services</Link>
                    <ul className={`dropdown ${activeIndex === 2 ? 'active' : ''}`}>
                        <li><Link to={`/services-detail`} className="link" onClick={handleLinkClick}>Service 1</Link></li>
                        <li><Link to={`/services-detail`} className="link" onClick={handleLinkClick}>Service 2</Link></li>
                        <li><Link to={`/services-detail`} className="link" onClick={handleLinkClick}>Service 3</Link></li>
                        <li><Link to={`/services-detail`} className="link" onClick={handleLinkClick}>Service 4</Link></li>
                        <li><Link to={`/services-detail`} className="link" onClick={handleLinkClick}>Service 5</Link></li>
                    </ul>
                </li>
                <li className="nav-item" onMouseLeave={() => toggleDropdown(null)}>
                    <Link to={`/contact`} className="link" onClick={handleLinkClick}>Contact</Link>
                </li>
            </ul>
            <div className="hamburger" onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </nav>
    );
}

export default NavbarMenuLight;
