import React from 'react';
import './services.css';
import {Link} from "react-router-dom"; // CSS dosyasını import et

const servicesData = [
    { id: 1, title: "Service 1", description: "Description for Service 1. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla efficitur.", image: "https://via.placeholder.com/300" },
    { id: 2, title: "Service 2", description: "Description for Service 2. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla efficitur.", image: "https://via.placeholder.com/300" },
    { id: 3, title: "Service 3", description: "Description for Service 3. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla efficitur.", image: "https://via.placeholder.com/300" },
    { id: 4, title: "Service 4", description: "Description for Service 4. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla efficitur.", image: "https://via.placeholder.com/300" },
    { id: 5, title: "Service 5", description: "Description for Service 5. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla efficitur.", image: "https://via.placeholder.com/300" }
];

const Services = () => {
    return (
        <div className="services-container">
            <h1>Our Services</h1>
            <div className="services-grid">
                {servicesData.map((service) => (
                    <div className="service-card" key={service.id}>
                        <img src={service.image} alt={service.title} className="service-image" />
                        <div className="service-content">
                            <h2>{service.title}</h2>
                            <p>{service.description}</p>
                            <Link to="/services-detail" className="link">
                                Continue Reading
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Services;
