import Slider2 from "../../slider/option-2/slider-2";
import FooterSection from "../../footer-section/footer-section";
import Portfolio from "../../portfolio/portfolio";
import References from "../../content/option-2/references";
import Teams from "../../content/option-2/teams";

const HomePage2 = () => {
    return (
        <>
            <Slider2/>
            <Portfolio/>
            <Teams/>
            <References/>
            <FooterSection/>
        </>
    )
}

export default HomePage2;