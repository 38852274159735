import React from 'react';
import './teams.css';
import { FaLinkedin, FaInstagram, FaFacebook } from 'react-icons/fa';

const teams = [
    {
        name: 'Team Member 1',
        image: 'https://via.placeholder.com/300',
        social: {
            linkedin: '#',
            instagram: '#',
            facebook: '#'
        },
    },
    {
        name: 'Team Member 2',
        image: 'https://via.placeholder.com/300',
        social: {
            linkedin: '#',
            instagram: '#',
            facebook: '#'
        },
    },
    {
        name: 'Team Member 3',
        image: 'https://via.placeholder.com/300',
        social: {
            linkedin: '#',
            instagram: '#',
            facebook: '#'
        },
    },
];

const Teams = () => {
    return (
        <div className="teams-container">
            {teams.map((member, index) => (
                <div key={index} className="team-card">
                    <div className="team-image" style={{ backgroundImage: `url(${member.image})` }}>
                        <div className="overlay">
                            <h3>{member.name}</h3>
                            <div className="social-icons">
                                <a href={member.social.linkedin}><FaLinkedin /></a>
                                <a href={member.social.instagram}><FaInstagram /></a>
                                <a href={member.social.facebook}><FaFacebook /></a>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Teams;
