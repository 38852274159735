import Slider1 from "../../slider/option-1/slider-1";
import Content1 from "../../content/option-1/content-1";
import FooterSection from "../../footer-section/footer-section";
import Portfolio from "../../portfolio/portfolio";

const HomePage1 = () => {
    return (
        <>
            <Slider1/>
            <Content1/>
            <Portfolio/>
            <FooterSection/>
        </>
    )
}

export default HomePage1;