import React from 'react';
import './references.css';

const References = () => {
    const references = [
        { id: 1, name: "Reference 1", image: "https://via.placeholder.com/150" },
        { id: 2, name: "Reference 2", image: "https://via.placeholder.com/150" },
        { id: 3, name: "Reference 3", image: "https://via.placeholder.com/150" },
        { id: 4, name: "Reference 4", image: "https://via.placeholder.com/150" }
    ];

    return (
        <div className="references-container">
            <h2 className="references-title">References</h2>
            <div className="references-grid">
                {references.map(reference => (
                    <div key={reference.id} className="reference-item">
                        <img src={reference.image} alt={reference.name} className="reference-image" />
                        <p className="reference-name">{reference.name}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default References;
