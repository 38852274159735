import React from "react";
import {Link} from "react-router-dom";

const ContentLeft=()=>{

    const data = Array(10).fill().map((_, i) => ({
        id: i + 1,
        text: `Announcement ${i + 1} : Consectetur adipiscing elit.`
    }));

    return(
        <div className="announcements">
            <h2>Announcements</h2>
            {data.map(e=>{
                return (
                    <div className="announcement-item">
                        <Link to={`/detail`} className="link">
                            {e.text}
                        </Link>
                    </div>
                )
            })}
        </div>
    )
}

export default ContentLeft;